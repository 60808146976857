import { useState, useEffect } from 'react'
import './Benefits.scss'

export default function Benefits(component) {

  const data = component.data ;

  if (!data) return null;

  return (
    <section className={`benefits ${data.round_corner_display ? "round-corner-display" : ""}`}>
      <div className="container">
        <div className="intro">
          <h2 dangerouslySetInnerHTML={{ __html: data.headline }}></h2>
          <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
        </div>
        <div className={`benefits-display display-${data.benefits.length}`}>
          {data.benefits.map(benefit => (
            <div className="benefit" key={benefit.subheader}>
              <div className="subheader">
                {/* <img src={benefit.icon.url} alt={benefit.subheader} /> */}
                <h3 dangerouslySetInnerHTML={{ __html: benefit.subheader }}></h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: benefit.body_copy }}></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
