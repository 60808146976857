import { useState, useEffect } from 'react'
import './Hero.scss'

import Dots from './assets/dots-graphic-bg.svg';
import DotsMobile from './assets/dots-graphic-bg-mobile.svg';

export default function Hero(component) {

  const data = component.data;

  if (!data) return null;

  return (
    <section className={`hero`}>
      <div className="round-corner">
        {data.background_image.url ?
          <>
            {data.mobile_background_image.url ?
              <>
              <img src={data.background_image.url} alt="II Technology" className="custom-image desktop" />
              <img src={data.mobile_background_image.url} alt="II Technology" className="custom-image mobile" />
            </>
              :
            <img src={data.background_image.url} alt="II Technology" className="custom-image"/>
            }
          </>
          :
          <img src={Dots} alt="II Technology" className="dots"/>
          }
        </div>
        < div className="page-title">
            <div className="container">
          <h1 dangerouslySetInnerHTML={{ __html: data.page_header }} className={`${data.header_color} ${!data.mobile_background_image.url ? "default" : ""}`}></h1>
            </div>
        </div>
      </section>
  )
}
