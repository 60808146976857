import { useState, useEffect } from 'react'
import './WhoWeAre.scss'

import TeamMember from './components/TeamMember';

export default function WhoWeAre(component) {

  const data = component.data ;

  if (!data) return null;

  return (
    <section className={`who-we-are ${data.round_corner_display ? "round-corner-display" : ""}`}>
      <div className="container">
        <div className="content-container">
          {data.headline ?
            <h2>{data.headline}</h2>
            : null
          }
          <div className="team-members">
            {data.team_members.map((member) => (
              <TeamMember member={ member} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
