import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Header.scss';

import NavArrow from './../assets/nav-arrow-children.svg';

export default function Header() {
  const location = useLocation();
  const options = useContext(OptionsContext);
  const [collapsedSubnavItems, setCollapsedSubnavItems] = useState([]);
  const [menuActive, setMenuActive] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const toggleSubnavCollapse = (name) => {
    if (collapsedSubnavItems.includes(name)) {
      setCollapsedSubnavItems(collapsedSubnavItems.filter(itemName => itemName !== name));
    } else {
      setCollapsedSubnavItems([...collapsedSubnavItems, name]);
    }
  };

  const toggleMenu = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveItem('home');
    } else if (location.pathname === '/armor-for-investors' || location.pathname === '/armor-for-advisors') {
      setActiveItem('armor');
    } else if (location.pathname === '/about') {
      setActiveItem('about');
    } else if (location.pathname === '/contact') {
      setActiveItem('contact');
    }
  }, [location.pathname]);

  if (!options) return null;

  return (
    <header className="primary-header">
      <div className="container">
        <div className="content-container">
          <div className="logo">
            <a href="/">
              <img src={options.logo_primary.url} alt="Logo" />
            </a>
          </div>
          <button className={`hamburger-menu ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <nav className={`primary-nav ${menuActive ? 'active' : ''}`}>
            <ul className="nav-items">
              {options.primary_nav.map((item, index) => (
                <li
                  className={`nav-item `}
                  key={item.link.post_title + index}
                >
                  {item.children.length ? (
                    <>
                      <a className={`dropdown ${activeItem === item.link.post_name || activeItem === item.label.toLowerCase() ? 'active' : 'inactive'}`} onClick={() => toggleSubnavCollapse(item.label)}>
                        {item.label}
                        <img src={NavArrow} alt="Dropdown" />
                      </a>
                      <ul className={`nav-item-children ${collapsedSubnavItems.includes(item.label) ? 'active' : ''}`}>
                        {item.children.map(child => (
                          <li className="nav-item-child" key={child.link.title}>
                            <a href={child.link.url} className="child-link">{child.link.title}</a>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <a href={`/${item.link.post_name}`} className={`no-children ${activeItem === item.link.post_name || activeItem === item.label.toLowerCase() ? 'active' : 'inactive'}`}>{item.link.post_title}</a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
