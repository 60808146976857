import { useState, useEffect } from 'react'
import './Contact.scss'

import Mountains from './assets/contact-graphic.png';
import Dots from './assets/dots-graphic-bg.png';

export default function Contact(component) {

  const data = component.data ;

  if (!data) return null;

  return (
    <section className={`contact ${data.round_corner_display ? "round-corner-display" : ""}`}>
      <div className="container">
        <div className="content-container">
          <div className="message">
            <h2 dangerouslySetInnerHTML={{ __html: data.headline }}></h2>
            <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
          </div>
          <div className="email-cta">
              <h4>{data.email_cta_label}</h4>
              <div className="email">
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}
