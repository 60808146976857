import Hero from './../Components/Hero/Hero'
import UserType from './../Components/UserType/UserType'
import Contact from './../Components/Contact/Contact'
import Benefits from './../Components/Benefits/Benefits'
import CopyBlock from '../Components/CopyBlock/CopyBlock'
import WhoWeAre from './../Components/WhoWeAre/WhoWeAre'
import AdvisorServicesCallout from './../Components/AdvisorServicesCallout/AdvisorServicesCallout';
import ContactAndLocation from './../Components/ContactAndLocation/ContactAndLocation';
import ArmorVsTrm from './../Components/ArmorVsTrm/ArmorVsTrm';


export default function Component({ component, pageData }) {
  const components = {
    hero: Hero,
    user_type: UserType,
    contact: Contact,
    benefits: Benefits,
    copy_block: CopyBlock,
    who_we_are: WhoWeAre,
    advisor_services_callout: AdvisorServicesCallout,
    contact_and_location: ContactAndLocation,
    armor_vs_trm: ArmorVsTrm,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component data={component} pageData={pageData} />
    )
  }

  return null;
}
