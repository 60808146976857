import { useState, useEffect } from 'react'
import './CopyBlock.scss'

export default function CopyBlock(component) {

  const data = component.data;

  if (!data) return null;

  return (
    <section className={`copy-block ${data.round_corner_display ? "round-corner-display" : ""}`} style={{textAlign: data.text_alignment}}>
      <div className="container">
          <div className="content-container">
            <div className={`copy display-${data.copy_snippets.length} ${data.display_in_columns ?  "columns" : ""}`}>
              {data.copy_snippets.map((snippet, index) => (
                <div className="snippet" key={`2-${snippet.heading}-${index}`}>
                  {snippet.heading.length ?
                    <h2 dangerouslySetInnerHTML={{ __html: snippet.heading }}></h2>
                    : null
                  }
                  {/* <h2 dangerouslySetInnerHTML={{ __html: snippet.heading }}></h2> */}
                  <p dangerouslySetInnerHTML={{__html: snippet.body_copy}}></p>
                </div>
              ))}
            </div>
          </div>
      </div>
    </section>
  )
}
