import { useEffect, useState, useContext } from 'react'
import { useLocation, NavLink, Link } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Footer.scss'

import Bg from './assets/footer-bg.png';

export default function Footer() {
  const location = useLocation();
  const options = useContext(OptionsContext);

  if (!options) return null;

  return (
    <footer className="primary-footer">
      <div className="container">
        <div className="content-container">
          <div className="logo">
            <img src={options.logo_secondary.url} alt="II Technology" />
          </div>
          <div className="legal">
            <p dangerouslySetInnerHTML={{__html: options.copyright}}></p>
          </div>
        </div>
      </div>
    </footer>
  )
}
