import { useState, useEffect } from 'react'
import './ArmorVsTrm.scss'

import Check from './assets/blue-checkmark.svg'
import X from './assets/red-x.svg'

export default function ArmorVsTrm(component) {

  const data = component.data;

  if (!data) return null;

  return (
    <section className={`armor-vs-trm ${data.round_corner_display ? "round-corner-display" : ""}`}>
      <div className="container">
          <div className="intro">
            <h2 dangerouslySetInnerHTML={{ __html: data.headline }}></h2>
            <p dangerouslySetInnerHTML={{__html: data.body_copy}}></p>
          </div>
          <div className="content-container chart">
          <table className="desktop">
            <thead>
              <tr>
                <th className="item-column"></th>
                <th>ARMOR</th>
                <th>Passive Risk Management</th>
              </tr>
            </thead>
            <tbody>
              {data.comparison_items.map(item => (
                <tr key={item.feature}>
                  <td className="item-column">{item.feature}</td>
                  <td><img src={Check} /></td>
                  <td><img src={X} /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mobile">
            {data.comparison_items.map(item => (
              <div className="feature" key={item.feature}>
                <div className="name">
                  <p dangerouslySetInnerHTML={{__html: item.feature}}></p>
                </div>
                <div className="status">
                  <div className="armor">
                    <p>ARMOR</p>
                    <img src={Check} />
                  </div>
                  <div className="trm">
                    <p>Passive Risk Management</p>
                    <img src={X} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
